// extracted by mini-css-extract-plugin
export var awsExpertImg = "PartnersMain-module--awsExpertImg--3uSgy";
export var awsExpertImgDiv = "PartnersMain-module--awsExpertImgDiv--lh5ce";
export var awsMainImage = "PartnersMain-module--awsMainImage--75mLx";
export var businessImage = "PartnersMain-module--businessImage--CRY89";
export var businessItem = "PartnersMain-module--businessItem--PV7lu";
export var businessItemImage = "PartnersMain-module--businessItemImage--NMjWi";
export var businessItemMain = "PartnersMain-module--businessItemMain--wfdes";
export var businessMain = "PartnersMain-module--businessMain--yxKz3";
export var businessText = "PartnersMain-module--businessText--b7vAT";
export var buttonClasses = "PartnersMain-module--buttonClasses--290-1";
export var carouselContainer = "PartnersMain-module--carouselContainer--eBCSt";
export var contactButton = "PartnersMain-module--contactButton--UzThE";
export var heroBackgroundCircleLeft = "PartnersMain-module--heroBackgroundCircleLeft--KSlzI";
export var heroBackgroundInnerCircleLeft = "PartnersMain-module--heroBackgroundInnerCircleLeft--zyqGN";
export var knowUs = "PartnersMain-module--knowUs--YaSyI";
export var knowUsImage = "PartnersMain-module--knowUsImage--HOTek";
export var knowUsSubDiv = "PartnersMain-module--knowUsSubDiv--zGBTW";
export var knowUsText = "PartnersMain-module--knowUsText--1r3xr";
export var partners = "PartnersMain-module--partners--Fb85v";
export var partnersBenefits = "PartnersMain-module--partnersBenefits--ebjIG";
export var partnersBenefitsCarousel = "PartnersMain-module--partnersBenefitsCarousel--09XrY";
export var partnersBenefitsInner = "PartnersMain-module--partnersBenefitsInner--NE7n+";
export var partnersBenefitsItem = "PartnersMain-module--partnersBenefitsItem--qVToT";
export var partnersBenefitsItemBusiness = "PartnersMain-module--partnersBenefitsItemBusiness--qNWAS";
export var partnersBenefitsItemBusinessTop = "PartnersMain-module--partnersBenefitsItemBusinessTop--zsA+p";
export var partnersBenefitsTech = "PartnersMain-module--partnersBenefitsTech--1BebJ";
export var partnersButtonsContainer = "PartnersMain-module--partnersButtonsContainer--SMNaE";
export var partnersMainSlider = "PartnersMain-module--partnersMainSlider--211pj";