// extracted by mini-css-extract-plugin
export var PartnerMobileCarousel = "PartnersTechnologySlider-module--PartnerMobileCarousel--8QTR4";
export var PartnersCarouselDesc = "PartnersTechnologySlider-module--PartnersCarouselDesc--NqzJh";
export var blogArrowContainer = "PartnersTechnologySlider-module--blogArrowContainer--ee2zy";
export var btnDiv = "PartnersTechnologySlider-module--btnDiv--mvFG9";
export var carousel = "PartnersTechnologySlider-module--carousel--zZGTc";
export var carouselDiv = "PartnersTechnologySlider-module--carouselDiv--mkbAs";
export var carouselImage = "PartnersTechnologySlider-module--carouselImage--Tb1z6";
export var carouselImageDiv = "PartnersTechnologySlider-module--carouselImageDiv---3SVp";
export var carouselImg = "PartnersTechnologySlider-module--carouselImg--DOQZ8";
export var carouselImgDiv = "PartnersTechnologySlider-module--carouselImgDiv--PcwCd";
export var carouselMainDiv = "PartnersTechnologySlider-module--carouselMainDiv--GSbfG";
export var carousel__container = "PartnersTechnologySlider-module--carousel__container--KFIvH";
export var carousel__slider = "PartnersTechnologySlider-module--carousel__slider--aQbMG";
export var carousel__sliderTrayWrapHorizontal = "PartnersTechnologySlider-module--carousel__slider-tray-wrap--horizontal--dCAHN";
export var carousel__sliderTrayWrapper = "PartnersTechnologySlider-module--carousel__slider-tray-wrapper--D98pQ";
export var caseStudiesDescription = "PartnersTechnologySlider-module--caseStudiesDescription--l7GHj";
export var contactButton = "PartnersTechnologySlider-module--contactButton--D4nnh";
export var paragraph = "PartnersTechnologySlider-module--paragraph--Gd642";
export var partnersMainSlider = "PartnersTechnologySlider-module--partnersMainSlider--W9gjY";
export var subDiv = "PartnersTechnologySlider-module--subDiv--6+cU7";