import React from "react";
import PartnersMain from "../components/PartnersUiComponents/PartnersMain";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import headerimg from "../images/imageMainSquare.png";

const Partners = () => {
  return (
    <Layout location="/partners">
      <SEO
        title="Partners"
        description="Our Partners help us to do exciting results for you. Learn more about our Partners."
        type="website"
        image={headerimg}
      />
      <PartnersMain />
    </Layout>
  );
};

export default Partners;
