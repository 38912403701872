import React, { FC, useContext } from "react";
import * as styles from "./PartnersMain.module.scss";
import tableDesktop from "../../images/imageMainSquare.webp";
import tableDesktopMobile from "../../images/mobile-images/imageMainSquareMobile.webp";
import { UseWindowSize } from "../hooks/UseWindowSize";

import awsMainImg from "../../images/awsMainIMG.jpg";

import partnersBusiness from "../../images/partnersBusiness.png";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import arrowRightWhite from "../../images/arrowRightWhite.svg";
import awsBadge from "../../images/aws-badge.png";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import CustomButton from "../Shared/CustomButton/CustomButton";
import PartnersTechnologyMobileSlider from "../OptimizationUiComponents/Slider/PartnersTechnologyMobileSlider";
import PartnersTechnologySlider from "../OptimizationUiComponents/Slider/PartnersTechnologySlider";
import {
  technologies,
  business,
  aws,
  technologiesEng,
  technologiesDE,
} from "./PartnersMain.helper";
import { CarouselProvider, Slider } from "pure-react-carousel";
import CaseStudiesSliderButtons from "../HomeUiComponents/CaseStudiesMain/Slider/CaseStudiesSliderButtons";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import translate from "../../i18n/translate";
import { LanguageContext } from "../../context/languageContext";

const PartnersMain: FC = () => {
  const width = UseWindowSize();
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.partners}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("partners-heading")}
          description={translate("partners-description")}
          homeBackground={tableDesktopMobile}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
          alt="Partners"
        />
      ) : (
        <HeroBanner
          heading={translate("partners-heading")}
          description={translate("partners-description")}
          homeBackground={tableDesktop}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
        />
      )}

      <InnerWrapper>
        <div className={styles.knowUs}>
          <div className={styles.knowUsImage}>
            <div className={styles.knowUsSubDiv}>
              <img
                src={awsMainImg}
                alt="High tech city at sunrise to showcase AWS at forefront of digitalisation and cloud infrastructure"
                className={styles.awsMainImage}
              />
              <div className={styles.awsExpertImgDiv}>
                <img
                  src={awsBadge}
                  alt="High tech city at sunrise to showcase AWS at forefront of digitalisation and cloud infrastructure"
                  className={styles.awsExpertImg}
                />
              </div>
            </div>
          </div>
          <div className={styles.knowUsText}>
            <h2 className="heading">
              <span>.</span>
              {aws?.title}
            </h2>
            <p>{aws.description}</p>
            <CustomButton
              text={translate("partners-learnMore")}
              image={arrowRightWhite}
              externalLink="https://aws.amazon.com"
              buttonClass={styles.contactButton}
            />
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.15}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
          </div>
        </div>

        <div className={styles.partnersBenefits}>
          <div className={styles.partnersBenefitsTech}>
            <h2 className="heading">
              <span>.</span>
              {translate("partners-techHeading")}
            </h2>
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.3}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
            <div className={styles.carouselContainer}>
              {width.width <= 770 ? (
                <PartnersTechnologyMobileSlider
                  data={language === "EN" ? technologiesEng : technologiesDE}
                />
              ) : (
                <CarouselProvider
                  className={"partners_carousel__container carousel__container"}
                  naturalSlideWidth={120}
                  naturalSlideHeight={125}
                  totalSlides={technologies.length}
                  visibleSlides={
                    width.width > 1700 ? 4 : width.width > 1200 ? 3 : 2
                  }
                  currentSlide={0}
                  infinite
                >
                  <CaseStudiesSliderButtons
                    sliderButtonsClassName={styles.partnersButtonsContainer}
                  />
                  <Slider
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {technologies?.map((item, index) => {
                      return (
                        <PartnersTechnologySlider
                          key={index}
                          sliderIndex={index}
                          sliderImage={item?.img}
                          about={item?.desc}
                          btn={item?.btn}
                        />
                      );
                    })}
                  </Slider>
                </CarouselProvider>
              )}
            </div>
          </div>
        </div>

        <div className={styles.businessMain}>
          <h2 className={styles.businessText}>
            <span>.</span>Business
          </h2>
          <div className={styles.businessImage}>
            <img
              src={partnersBusiness}
              alt="Intersecting roads and highways to represent coordinated cooperation between many businesses"
              width="100%"
            />
          </div>
          <div className={styles.businessItemMain}>
            {business?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.partnersBenefitsItemBusinessTop}
                >
                  <div className={styles.businessItemImage}>
                    <img src={item?.img} alt="Business Pic" />
                  </div>
                  <p>{item?.desc}</p>
                  <div>{item?.btn} </div>
                </div>
              );
            })}
          </div>
        </div>
      </InnerWrapper>
    </div>
  );
};

export default PartnersMain;
