import React from "react";
import ohtru from "../../images/ohtru.png";
import hashiCorp from "../../images/hashiCorp.png";
import dataDog from "../../images/dataDog.png";
import Sysdig from "../../images/Sysdig.png";
import hasura from "../../images/hasura.png";
import CustomButton from "../../../src/components/Shared/CustomButton/CustomButton";
import business1 from "../../images/business1.png";
import business2 from "../../images/business2.png";
import business3 from "../../images/business3.png";
import business4 from "../../images/business4.png";
import business5 from "../../images/business5.png";
import arrowRightWhite from "../../images/arrowRightWhite.svg";
import * as styles from "./PartnersMain.module.scss";
import translate from "../../i18n/translate";

export const technologies = [
  {
    img: hashiCorp,
    desc: translate("partners-techPartner1"),
    btn: (
      <CustomButton
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://hashicorp.com"}
      />
    ),
  },
  {
    img: dataDog,
    desc: translate("partners-techPartner2"),
    btn: (
      <CustomButton
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://datadoghq.com"}
      />
    ),
  },
  {
    img: Sysdig,
    desc: translate("partners-techPartner3"),
    btn: (
      <CustomButton
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://sysdig.com"}
      />
    ),
  },
  {
    img: ohtru,
    desc: translate("partners-techPartner4"),
    btn: (
      <CustomButton
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://linkedin.com/company/ohtru"}
      />
    ),
  },
  {
    img: hasura,
    desc: translate("partners-techPartner5"),
    btn: (
      <CustomButton
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://hasura.io/"}
      />
    ),
  },
];

export const technologiesDE = [
  {
    img: hashiCorp,
    desc: "HashiCorp ist führend in der Infrastrukturautomatisierung für Multi-Cloud-Umgebungen. Sie entwickeln Lösungen, die Innovationen in globalen Unternehmen ermöglichen und Ihnen ein einheitliches Cloud-Betriebsmodell über konsistente Workflows bieten, um Ihre Infrastruktur mit beliebigen Anwendungen bereitzustellen, zu sichern, zu vernetzen und zu betreiben. Alle Basistechnologien sind Open Source und wurden seit ihrer Gründung offen entwickelt.",
    btn: (
      <CustomButton
        text={"Lerne mehr"}
        image={arrowRightWhite}
        externalLink={"https://hashicorp.com"}
      />
    ),
  },
  {
    img: dataDog,
    desc: "Datadog ist eine Überwachungs-, Sicherheits- und Analyseplattform für Cloud-Anwendungen. Die SaaS-Plattform integriert und automatisiert Infrastruktur-Monitoring, Anwendungs-Performance-Monitoring und Log-Management, um eine einheitliche Echtzeitbeobachtung Ihres gesamten Technologie-Stacks zu gewährleisten.",
    btn: (
      <CustomButton
        text={"Lerne mehr"}
        image={arrowRightWhite}
        externalLink={"https://datadoghq.com"}
      />
    ),
  },
  {
    img: Sysdig,
    desc: "Sysdig ist ein Anbieter mit Fokus auf Cloud- und Containersicherheit für Unternehmen und deren DevOps-Teams. Sie fördern die sichere DevOps-Nutzung und helfen Organisationen dabei Container, Kubernetes und Cloud-Dienste zuverlässig zu sichern. Mit der “Sysdig Secure DevOps Platform” sichern Cloud-Teams die Build-Pipeline, erkennen und reagieren auf Laufzeitbedrohungen, überprüfen kontinuierlich Compliance und überwachen Infrastrukturen und Services.",
    btn: (
      <CustomButton
        text={"Lerne mehr"}
        image={arrowRightWhite}
        externalLink={"https://sysdig.com"}
      />
    ),
  },
  {
    img: ohtru,
    desc: "OHTRU ist eine “FinOps Platform Collaborative”, Echtzeit-Cloud-Finanzmanagement und Cloud-Kostenoptimierung für mehrere Clouds, Teams und Konten. OHTRU unterstützt SRE-, DevOps- und FinOps-Teams dabei effizientere Architekturen zu entwickeln und die Cloud-Kosten zu senken.",
    btn: (
      <CustomButton
        text={"Lerne mehr"}
        image={arrowRightWhite}
        externalLink={"https://linkedin.com/company/ohtru"}
      />
    ),
  },
  {
    img: hasura,
    desc: "Hasura makes data access easy, by instantly composing a GraphQL API that is backed by databases and services so that the developer team (or API consumers) get immediately productive. The nature of GraphQL itself and Hasura’s dynamic approach makes integration and iteration easy.",
    btn: (
      <CustomButton
        text={"Lerne mehr"}
        image={arrowRightWhite}
        externalLink={"https://hasura.io/"}
      />
    ),
  },
];

export const technologiesEng = [
  {
    img: hashiCorp,
    desc: "HashiCorp is the leader in infrastructure automation for multi-cloud environments. They are building solutions that enable innovation at global enterprises, offering them a common cloud operating model via consistent workflows to provision, secure, connect, and run their infrastructure with any application. All foundational technologies are open source and developed openly, and have been since our founding.",
    btn: (
      <CustomButton
        text={"Learn more"}
        image={arrowRightWhite}
        externalLink={"https://hashicorp.com"}
      />
    ),
  },
  {
    img: dataDog,
    desc: "Datadog is the monitoring, security and analytics platform for cloud applications. The SaaS platform integrates and automates infrastructure monitoring, application performance monitoring and log management to provide unified, real-time observability of your entire technology stack.",
    btn: (
      <CustomButton
        text={"Learn more"}
        image={arrowRightWhite}
        externalLink={"https://datadoghq.com"}
      />
    ),
  },
  {
    img: Sysdig,
    desc: "Sysdig is a provider of both cloud and container security for enterprises and their DevOps teams. It drives secure DevOps movement, helping organizations to confidently secure containers, Kubernetes, and cloud services. With the Sysdig Secure DevOps Platform, cloud teams secure the build pipeline, detect and respond to runtime threats, continuously validate compliance, and monitor and troubleshoot cloud infrastructure and services.",
    btn: (
      <CustomButton
        text={"Learn more"}
        image={arrowRightWhite}
        externalLink={"https://sysdig.com"}
      />
    ),
  },
  {
    img: ohtru,
    desc: "OHTRU is a next-generation FinOps Platform Collaborative, Real-Time Cloud Financial Management and Cloud Cost Optimization across multiple clouds, teams and accounts. OHTRU helps SRE, DevOps, and FinOps teams to build more efficient architectures, map costs to the business and cut the cloud costs.",
    btn: (
      <CustomButton
        text={"Learn more"}
        image={arrowRightWhite}
        externalLink={"https://linkedin.com/company/ohtru"}
      />
    ),
  },
  {
    img: hasura,
    desc: "Hasura makes data access easy, by instantly composing a GraphQL API that is backed by databases and services. Developers can build business logic that publishes and consumes events, that reacts to changes in data or gets scheduled based on time. Hasura automates 50-80% of your API development work, making your application development or modernisation project tractable and within a much shorter timeframe.",
    btn: (
      <CustomButton
        text={"Learn more"}
        image={arrowRightWhite}
        externalLink={"https://hasura.io/"}
      />
    ),
  },
];

export const business = [
  {
    img: business1,
    desc: translate("partners-businessPartner1"),
    btn: (
      <CustomButton
        buttonClass={styles.buttonClasses}
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://softwareallianz.de"}
      />
    ),
  },
  {
    img: business2,
    desc: translate("partners-businessPartner2"),
    btn: (
      <CustomButton
        buttonClass={styles.buttonClasses}
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://eurocloudnative.de"}
      />
    ),
  },
  {
    img: business3,
    desc: translate("partners-businessPartner3"),
    btn: (
      <CustomButton
        buttonClass={styles.buttonClasses}
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://realizon.eu/"}
      />
    ),
  },
  {
    img: business4,
    desc: translate("partners-businessPartner4"),
    btn: (
      <CustomButton
        buttonClass={styles.buttonClasses}
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://ginkgo-analytics.com"}
      />
    ),
  },
  {
    img: business5,
    desc: translate("partners-businessPartner5"),
    btn: (
      <CustomButton
        buttonClass={styles.buttonClasses}
        text={translate("partners-learnMore")}
        image={arrowRightWhite}
        externalLink={"https://whereversim.de"}
      />
    ),
  },
];

export const aws = {
  title: "AWS",
  description: translate("partners-awsDescription"),
};
